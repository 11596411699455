@import "../ref.scss";

.Home {
  width: 100%;
}

.Cover {
  position: relative;
  width: 100%;

  @media (orientation: portrait) {
    height: 100vh;
  }

  @media (orientation: landscape) {
    height: ref(177.78);
  }

  background: #000 url("../assets/cover_9x16_min.jpg") no-repeat;
  background-size: cover;
}

.CoverText {
  padding: ref(24.5) ref(4.5) ref(4.5) ref(4.5);
  font-size: ref(3.2);
  white-space: pre;
  text-align: start;
}

.Content {
  padding: ref(20) 0 0 0;
}

.SectionTitle {
  padding: ref(11.56) 0;
  margin: 0;
  text-align: center;
}

.Footer {
  margin-top: ref(30);
}
