@import "../ref.scss";

.Expandable {
  overflow: hidden;
  transition: height .1s ease-out;
}

.Content {
  border: solid 1px transparent;
  border-left: none;
  border-right: none;
}

.Toggle {
  display: block;
  appearance: none;
  background: transparent;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: ref(3.2);
  margin: 0 auto;
  padding: 0;
  border-bottom: dashed 1px currentColor;
}
