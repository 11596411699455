@import "../ref.scss";

.Contacts {
  display: flex;
  flex-flow: column nowrap;
  gap: ref(2);
}

.Link {
  font-size: ref(3.2);
}
