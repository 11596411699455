@import "../ref.scss";

.CourseCover {
  width: ref(100);
  height: ref(56.25);
}

.CourseList {
  display: flex;
  flex-flow: column nowrap;
  gap: ref(11.55);
}

.CourseLine {
  display: flex;
  flex-flow: row nowrap;
  margin: ref(2.25) ref(4.5);
  align-items: baseline;
  justify-content: space-between;
  gap: ref(2.25);
}

.CourseTitle {
  flex: auto;
  margin: 0;
  font-size: ref(3.2);
  font-weight: normal;
}

.CourseNotes {
  font-size: ref(3.2);
  margin: ref(2) ref(4.5) ref(4.5) ref(4.5);
}

.CourseBuyBtn {
  flex: none;
  height: ref(7.12);
  border-radius: ref(3.56);
  padding: 0 ref(3);
  line-height: ref(7.12);
  font-size: ref(3);
  background: linear-gradient(
    to right,
    rgb(0, 54, 135) 0%,
    rgb(1, 192, 250) 100%
  );
  text-decoration: none;
}

.CoursePriceLabel {
  flex: none;
  font-size: ref(3.2);
  font-weight: bold;
}

.CourseDescr {
  font-size: ref(3.2);
  padding: 0 ref(4.5);
}
