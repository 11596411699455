@import "../ref.scss";

.Footer {
  padding: ref(4.5) 0;
}

.OrgInfo {
  margin: 0 ref(4.5);
  font-size: ref(3.2);
  line-height: ref(4.8);

  p {
    margin: 0;
  }
}

.Copyright {
  margin: ref(8) 0;
  text-align: center;
  font-size: ref(2.4);
}
