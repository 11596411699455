@import "../ref.scss";

.Menu {
  background: #000;
}

.Content {
  display: flex;
  flex-flow: column nowrap;
  gap: ref(4.5);
  padding: 0 0 ref(9) 0;
}

.MenuSection {
  padding: 0 ref(4.5);
}

.MenuLabel {
  margin: 0;
  font-size: ref(3.2);
}

.Langs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: ref(4.5);
}

.LangBtn {
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: ref(3.2);
  color: currentColor;
  background: transparent;
}

.Contacts {
  padding: 0 ref(4.5);
}
