@use "sass:math";
@import "../ref.scss";

.Header {
}

.LeadingRow {
  width: ref(100);
  height: ref(20);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0);

  &.MenuOpen {
    background: rgba(0, 0, 0, 1);
  }
}

.HeaderLogo {
  width: ref(20);
  height: ref(20);
  object-fit: contain;
  object-position: center;
}

.HeaderTitle {
  flex: auto;
  color: #fff;
  font-size: ref(3.8);
  text-align: center;
  font-weight: 300;
  margin: 0;
}

.HeaderMenuBtn {
  width: ref(20);
  text-align: center;
}

.MenuBtn {
  width: ref(20);
  height: ref(20);
  appearance: none;
  background: transparent;
  border: none;
  color: currentColor;
  padding: ref(math.div(9.26, 2));
  cursor: pointer;
}

.MenuBtnIcon {
  display: block;
  width: ref(10.84);
  height: ref(10.84);
  border: dashed 1px rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.01);
  box-shadow:
    0 0 ref(2.5) rgba(224, 165, 255, 0.1),
    0 0 ref(1) rgba(255, 255, 255, 0.1)
  ;
}

.Menu {
  position: absolute;
  top: ref(20);
  left: 0;
  width: ref(100);
  height: 0;
  overflow: hidden;

  &.MenuOpen {
    height: auto;
  }
}
