@import "../../ref.scss";

.Container {
  padding: ref(6) ref(4);
  min-height: 100vh;
  background: rgb(33, 31, 38);
  background: linear-gradient(15deg, rgba(33, 31, 38, 1) 0%, rgba(13, 21, 34, 1) 35%, rgba(4, 5, 11, 1) 100%);
}

.Container {
  p, li {
    font-family: "fira-sans", sans-serif;
    font-size: ref(4);
  }
}
