.App {
  position: relative;
  background: #000;
  overflow-x: hidden;
  overflow-y: scroll;

  @supports (overflow: overlay) {
    overflow-y: overlay;
  }
}

@media (max-width: 900px) {
  .App {
    width: 100vw;
    height: 100vh;
  }
}

@media (min-width: 901px) {
  .App {
    width: 56.25vh;
    height: 100vh;
    margin: 0 auto;
    box-shadow: 0 0 2vw rgba(0, 0, 0, .4);
  }

  body {
    background: #000 url("./assets/bg-blur.png") no-repeat;
    background-size: 100% auto;
    background-position: top center;
  }
}

.Header {
  position: fixed;
  z-index: 1;

  &.Scrolled {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: var(--scroll-amount, 0);
      background: #000;
      z-index: -1;
    }
  }
}
